import { Link } from 'react-router-dom';

export default function About() {
    return (
        <div className='flex relative w-full h-full'>
            <section className='flex flex-col h-screen w-full items-center justify-center gap-10'>
                <div className="flex text-xl font-bold w-2/3">
                    [About Page]
                    <br />
                    <br />
                    Fun fact: We are developing this website ourselves as we go.
                    Some parts are still being worked on, and will be updated as we complete them.
                    Thanks for understanding and stay tuned!
                </div>
                <Link className="bg-red-500 hover:bg-red-700 text-white text-center py-2 px-4 font-bold rounded-full" to={"/"}>
                    Home
                </Link>
            </section>
        </div >
    );
}
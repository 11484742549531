import { Link } from "react-router-dom";

export default function NoMatch() {
    return (
        <div className='flex flex-col relative w-full h-full'>
            <section className='flex flex-col h-screen w-full items-center justify-center bg-gray-500 gap-20'>
                <p className="flex w-2/3 font-mono font-bold text-2xl lg:justify-center">
                    Uh-oh!  You are clearly lost, explorer.  Click below for directions.
                </p>
                <Link className="bg-red-500 hover:bg-red-700 text-white text-center py-2 px-4 font-bold rounded-full" to={"/"}>
                    Home
                </Link>
            </section>
        </div >
    );
}
import { Link } from "react-router-dom";

export default function Header() {
  const handleClick = () => {
    const menu = document.getElementById("menu");
    if (!menu.hasAttribute("hidden")) {
      menu.classList.toggle("hidden");
    }
  };

  return (
    <nav className="fixed w-full z-20 top-0 start-0 bg-gray-500">
      <div className="container max-w-screen-xxxl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link
          className="flex items-center space-x-3 rtl:space-x-reverse text-white font-bold text-2xl lg:text-4xl font-['Lobster']"
          aria-current="page"
          to={"/"}
        >
          Tomato Pie
        </Link>
        <button
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg lg:hidden focus:outline-none focus:ring-2 focus:ring-gray-200"
          data-collapse-toggle="menu"
          type="button"
          aria-controls="menu"
          aria-expanded="false"
          onClick={handleClick}
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div className="hidden w-full lg:block lg:w-auto" id="menu">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 bg-gray-500">
            <li>
              <Link
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0"
                to={"/"}
                onClick={handleClick}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0"
                to={"/Menu"}
                onClick={handleClick}
              >
                Menu
              </Link>
            </li>
            <li>
              <Link
                className="block py-2 px-3 text-white rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0"
                to={"/Contact"}
                onClick={handleClick}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

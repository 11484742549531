import { Link } from 'react-router-dom';
import EmploymentAppPdf from '../app/assets/EmploymentApplication.pdf'

export default function Contact() {
    const downloadStats = () => {
        window.gtag('event', 'Application_Download', {
            'event_category': 'buttonClick',
            send_to: 'G-2HX3V2HVM3',
        });
    }

    return (
        <div className="pt-24">
            <div className="container flex flex-wrap flex-col md:flex-row px-3 mx-auto items-center gap-20 md:gap-0">
                <div className='flex flex-col w-full md:w-1/2 justify-center items-center text-center md:text-left gap-6'>
                    <h1 className="text-3xl font-bold uppercase tracking-loose">
                        Contact Us
                    </h1>
                    <div className='flex flex-col text-md gap-5'>
                        <a className='flex flex-row gap-5 items-center' href='mailto:support@paragonpizza.com'>
                            <svg className='w-10 aspect-square' xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                                <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                            </svg>
                            <h2 className='my-4 font-bold leading-tight'>
                                support@paragonpizza.com
                            </h2>
                        </a>
                        <a className='flex flex-row gap-5 items-center' href='https://facebook.com/tomatopierestaurant'>
                            <svg className='w-10 aspect-square' xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                            </svg>
                            <h2 className='my-4 font-bold leading-tight'>
                                facebook.com/TomatoPieRestaurant
                            </h2>
                        </a>
                    </div>
                </div>
                <div className='flex flex-col w-full md:w-1/2 justify-center items-center text-center md:text-left gap-6'>
                    <h1 className="text-3xl font-bold uppercase tracking-loose">
                        Employment
                    </h1>
                    <div className='flex flex-col text-xl gap-3 items-center'>
                        <p className="w-full leading-tight text-center">
                            (Must be at least 16 to apply)
                        </p>
                        <Link className="mx-auto lg:mx-0 bg-red-500 hover:bg-red-700 font-bold rounded-full my-6 py-4 px-8 shadow-lg"
                            to={EmploymentAppPdf} download="EmploymentApplication.pdf"
                            target="_blank"
                            rel="noreferrer"
                            onClick={downloadStats}>
                            Download Application
                        </Link>
                        <p className='my-4 text-lg font-bold leading-tight'>
                            Note: You can submit your application via email!
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
}
//w-fit bg-red-500 hover:bg-red-700 text-white text-center py-2 px-4 rounded-full